import { Spin } from 'antd';
import { useRouter } from 'next/router';
import { createContext, useEffect, useState, Dispatch, SetStateAction } from 'react';
import { ChildrenT } from '~/@customTypes/generic';

export type LoaderContextT = {
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
};
export const LoaderContext = createContext<LoaderContextT>({
  loading: false,
  setLoading: (value: SetStateAction<boolean>) => value,
});

type Props = ChildrenT;

export default function LoaderProvider({ children }: Props) {
  const [loading, setLoading] = useState(false);
  const router = useRouter();

  useEffect(() => {
    const handleOnRouteChangeStart = () => {
      setLoading(true);
    };
    const handleOnRouteChangeComplete = () => {
      setLoading(false);
    };

    router.events.on('routeChangeStart', handleOnRouteChangeStart);
    router.events.on('routeChangeComplete', handleOnRouteChangeComplete);
    router.events.on('routeChangeError', handleOnRouteChangeComplete);

    return () => {
      router.events.off('routeChangeStart', handleOnRouteChangeStart);
      router.events.off('routeChangeComplete', handleOnRouteChangeComplete);
      router.events.off('routeChangeError', handleOnRouteChangeComplete);
    };
  }, [router.events, router.asPath]);

  return (
    <LoaderContext.Provider value={{ setLoading, loading }}>
      <Spin spinning={loading} tip='Loading...' size='large'>
        {children}
      </Spin>
    </LoaderContext.Provider>
  );
}
