import { AxiosResponse } from 'axios';
import { PaginateResponseT, ResponseT } from '~/@customTypes/generic';
import { ProductT } from '~/@customTypes/product.type';
import { Backend } from '~/v2/utils/service';
import { ProductGroupImageT, ProductGroupsT } from './product.type';

export const fetchProductGroups = async (params: {
  locale: string;
  currency: string;
}): Promise<ResponseT<ProductGroupsT[]> | null> => {
  try {
    const result: AxiosResponse<ResponseT<ProductGroupsT[]>> = await Backend.vInstance.get('/product-groups', {
      params,
    });

    if (result.status === 200 && result.data) {
      return new Promise((resolve) => resolve(result.data));
    }
  } catch (error) {
    console.log(error);
  }

  return new Promise((resolve) => resolve(null));
};

export const fetchProductsByGroup = async (
  groupId: string,
  params?: { locale: string; currency: string; limit?: number | undefined; page?: number | undefined } | undefined
): Promise<PaginateResponseT<ProductT[]> | null> => {
  try {
    const result: AxiosResponse<PaginateResponseT<ProductT[]>> = await Backend.vInstance.get(
      `/product-groups/${groupId}`,
      {
        params,
      }
    );

    if (result.status === 200 && result.data) {
      return new Promise((resolve) => resolve(result.data));
    }
  } catch (error) {
    console.log(error);
  }

  return new Promise((resolve) => resolve(null));
};

export const fetchProductGroupImages = async (groupId: string): Promise<ResponseT<ProductGroupImageT[]> | null> => {
  try {
    const result: AxiosResponse<ResponseT<ProductGroupImageT[]>> = await Backend.vInstance.get(
      `/product-groups/${groupId}/images`
    );

    if (result.status === 200 && result.data) {
      return new Promise((resolve) => resolve(result.data));
    }
  } catch (error) {
    console.log(error);
  }

  return new Promise((resolve) => resolve(null));
};
