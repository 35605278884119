/**
 *
 * @description ConfigProvider.tsx
 * @author yikkok <yikok.yong@gmail.com>
 * @version 1.0.0
 * @since 31 October 2021
 *
 */

import { AxiosResponse } from 'axios';
import { createContext, Dispatch, SetStateAction, useEffect, useState } from 'react';
import { CurrencyT } from '~/@customTypes/currencies.type';
import { ChildrenT, ResponseT } from '~/@customTypes/generic';
import { axiosInstanceWithoutProxy } from '../utils/service';

export type ConfigContextT = {
  currency: string;
  setCurrency: Dispatch<SetStateAction<string>>;
  currencies: Partial<Array<CurrencyT>>;
};
export const ConfigContext = createContext<ConfigContextT>({
  currency: 'MYR',
  setCurrency: (value: SetStateAction<string>) => value,
  currencies: [],
});

type Props = ChildrenT;

export default function ConfigProvider({ children }: Props) {
  const [currencies, setCurrencies] = useState<Partial<Array<CurrencyT>>>([]);
  const [currency, setCurrency] = useState('MYR');

  useEffect(() => {
    (async () => {
      const response: void | AxiosResponse<ResponseT<Array<CurrencyT>>> = await axiosInstanceWithoutProxy
        .get('/v1/core/currencies')
        .catch((error) => {
          console.log(error);
        });

      if (response && response.status === 200) {
        setCurrencies(response.data.data || []);
      }
    })();
  }, []);

  return <ConfigContext.Provider value={{ currency, setCurrency, currencies }}>{children}</ConfigContext.Provider>;
}
