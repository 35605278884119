/**
 *
 * @description useLocalProfile.ts Custom Hooks
 * @author yikkok <yikok.yong@gmail.com>
 * @version 1.0.0
 * @since 13 November 2021
 *
 */

import { useEffect, useState } from 'react';
import { UserT } from '~/@customTypes/user.type';

export default function useLocalProfile(): { profile: UserT | null } {
  const [profile, setProfile] = useState<UserT | null>(null);

  useEffect(() => {
    const userStorage = localStorage.getItem('user');

    if (userStorage) {
      setProfile(JSON.parse(userStorage));
    }
  }, []);

  return { profile };
}
