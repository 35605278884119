/**
 *
 * @description PaymentMethods.tsx
 * @author yikkok <yikok.yong@gmail.com>
 * @version 1.0.0
 * @since 13 November 2021
 *
 */

import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { PaymentMethodsContext, PaymentMethodsContextT } from '~/v2/context/PaymentMethodsProvider';
import { savePaymentMethod } from '~/v2/services/payment.services';

export default function PaymentMethods() {
  const { paymentMethods, selectedPaymentMethod, setSelectedPaymentMethod } =
    useContext<Partial<PaymentMethodsContextT>>(PaymentMethodsContext);

  return (
    <div>
      <h2 className='text-lg leading-6 font-medium text-gray-900'>
        <FormattedMessage id='app.common.payment_methods' defaultMessage='Payment Methods' />
      </h2>
      <p className='mt-1 text-sm text-gray-500'>
        <FormattedMessage
          id='app.payment.choose_payment_method'
          defaultMessage='Choose your preferred payment method'
        />
      </p>
      <fieldset className='mt-2'>
        <legend className='sr-only'>Payment Method</legend>
        <div className='divide-y divide-gray-200'>
          {paymentMethods?.map((payment, methodIdx) => (
            <div key={methodIdx} className='relative flex items-start py-4'>
              <div className='min-w-0 flex-1 text-sm'>
                <label htmlFor={`payment-method-${payment.method}`} className='font-medium text-gray-700'>
                  {payment.method_title}
                </label>
                <p id={`payment-method-${payment.method}-description`} className='text-gray-500'>
                  {payment.description}
                </p>
              </div>
              <div className='ml-3 flex items-center h-5'>
                <input
                  id={`payment-method-${payment.method}`}
                  aria-describedby={`payment-method-${payment.method}-description`}
                  name='payment-method'
                  type='radio'
                  className='focus:ring-main-300 h-4 w-4 text-main-400 border-gray-300'
                  checked={selectedPaymentMethod === payment.method || false}
                  onChange={async () => {
                    setSelectedPaymentMethod?.(payment.method);

                    await savePaymentMethod(payment.method);
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </fieldset>
    </div>
  );
}
