/**
 *
 * @description SearchProvider.tsx
 * @author yikkok <yikok.yong@gmail.com>
 * @version 1.0.0
 * @since 22 February 2022
 *
 */

import { createContext, useState, Dispatch, SetStateAction } from 'react';
import { ChildrenT } from '~/@customTypes/generic';

export type SearchContextT = {
  displaySearch: boolean;
  setDisplaySearch: Dispatch<SetStateAction<boolean>>;
};

export const SearchContext = createContext<SearchContextT>({
  displaySearch: true,
  setDisplaySearch: (value: boolean) => value,
});

type Props = ChildrenT;
export default function SearchProvider({ children }: Props) {
  const [displaySearch, setDisplaySearch] = useState(false);

  return <SearchContext.Provider value={{ displaySearch, setDisplaySearch }}>{children}</SearchContext.Provider>;
}
