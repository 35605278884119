import { AxiosResponse } from 'axios';
import { CartT } from '~/@customTypes/cart.type';
import { ResponseT } from '~/@customTypes/generic';
import { axiosInstanceWithoutProxy } from '../utils/service';

export const savePaymentMethod = async (method: string): Promise<ResponseT<CartT> | null> => {
  try {
    const result: void | AxiosResponse<ResponseT<CartT>> = await axiosInstanceWithoutProxy.post(
      '/checkout/save-payment?token=true',
      { payment: { method } }
    );

    if (result && result.status === 200 && result.data) {
      return new Promise((resolve) => resolve(result.data));
    }
  } catch (error) {
    console.log(error);
  }

  return new Promise((resolve) => resolve(null));
};
