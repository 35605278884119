/**
 *
 * @description Address.create.tsx
 * @author yikkok <yikok.yong@gmail.com>
 * @version 1.0.0
 * @since 23 January 2022
 *
 */

import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';

import { AddressContext, AddressContextT } from '~/v2/context/AddressProvider';

export default function AddressCreate() {
  const { openModal } = useContext<Partial<AddressContextT>>(AddressContext);

  return (
    <div>
      <button
        type='button'
        onClick={openModal}
        className='px-4 py-2 mt-4 text-sm font-medium text-white bg-main-400 hover:bg-main-500 rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'
      >
        <FormattedMessage id='app.address.create' defaultMessage='Create New Address' />
      </button>
    </div>
  );
}
