class SaveAddressUser {
  firstName: string;
  lastName: string;
  email: string;

  setFirstName = (value: string) => {
    this.firstName = value;
  };

  setLastName = (value: string) => {
    this.lastName = value;
  };

  setEmail = (value: string) => {
    this.email = value;
  };
}

export default SaveAddressUser;
