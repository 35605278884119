/**
 *
 * @description CategoriesProvider.tsx
 * @author yikkok <yikok.yong@gmail.com>
 * @version 1.0.0
 * @since 31 October 2021
 *
 */

import { AxiosResponse } from 'axios';
import { createContext, useEffect, useState } from 'react';
import { CategoryT } from '~/@customTypes/categories.type';
import { ChildrenT, PaginateResponseT } from '~/@customTypes/generic';
import { axiosInstanceWithoutProxy } from '../utils/service';

export type CategoriesContextT = {
  categories: Partial<Array<CategoryT>>;
};

export const CategoriesContext = createContext<Partial<CategoriesContextT>>({});

type Props = ChildrenT;

export default function CategoriesProvider({ children }: Props) {
  const [categories, setCategories] = useState<Partial<Array<CategoryT>>>([]);

  useEffect(() => {
    (async () => {
      const response: void | AxiosResponse<PaginateResponseT<Array<CategoryT>>> = await axiosInstanceWithoutProxy
        .get('/categories')
        .catch((err) => {
          console.log(err);
        });

      if (response && response.status === 200) {
        setCategories(response.data?.data || []);
      }
    })();
  }, []);

  return <CategoriesContext.Provider value={{ categories }}>{children}</CategoriesContext.Provider>;
}
