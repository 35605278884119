/**
 *
 * @description CheckoutForm.tsx
 * @author yikkok <yikok.yong@gmail.com>
 * @version 1.0.0
 * @since 13 November 2021
 *
 */

import { Field, useFormikContext } from 'formik';
import { FocusEvent, useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import BillingAddress from '~/v2/components/address/BillingAddress';
import ShippingAddress from '~/v2/components/address/ShippingAddress';
import PaymentMethods from '~/v2/components/payment/PaymentMethods';
import ShippingRate from '~/v2/components/shipping/ShippingRate';
import { AddressContext, AddressContextT } from '~/v2/context/AddressProvider';
import useLocalProfile from '~/v2/hooks/useLocalProfile';
import SaveAddressUser from '~/v2/model/SaveAddressUser';
import { CheckoutFormValues } from './CheckoutPage';

export const shippingAddressUser = new SaveAddressUser();
export const billingAddressUser = new SaveAddressUser();

export default function CheckoutForm() {
  const { onUpdateSameAsShiping } = useContext<Partial<AddressContextT>>(AddressContext);
  const { setFieldValue, values } = useFormikContext<CheckoutFormValues>();
  const { profile } = useLocalProfile();

  useEffect(() => {
    if (profile) {
      setFieldValue('email-address', profile.email);
      setFieldValue('first-name', profile.first_name);
      setFieldValue('last-name', profile.last_name);

      setFieldValue('billing-email-address', profile.email);
      setFieldValue('billing-first-name', profile.first_name);
      setFieldValue('billing-last-name', profile.last_name);

      shippingAddressUser.setFirstName(profile.first_name);
      shippingAddressUser.setLastName(profile.last_name);
      shippingAddressUser.setEmail(profile.email);

      if (values['same-as-shipping']) {
        billingAddressUser.setFirstName(profile.first_name);
        billingAddressUser.setLastName(profile.last_name);
        billingAddressUser.setEmail(profile.email);
      }
    }
  }, [profile]);

  useEffect(() => {
    onUpdateSameAsShiping?.(values['same-as-shipping']);
  }, [values['same-as-shipping']]);

  return (
    <div className='pt-16 pb-36 px-4 sm:px-6 lg:pb-16 lg:px-0 lg:row-start-1 lg:col-start-1'>
      <div className='max-w-lg mx-auto lg:max-w-none'>
        <section aria-labelledby='contact-info-heading'>
          <h2 id='contact-info-heading' className='text-lg font-medium text-gray-900'>
            <FormattedMessage id='app.common.shipping_information' defaultMessage='Shipping Information' />
          </h2>

          <div className='mt-6 grid grid-cols-2 gap-2'>
            <div>
              <label htmlFor='first-name' className='block text-sm font-medium text-gray-700'>
                <FormattedMessage id='app.common.first_name' defaultMessage='First Name' />
              </label>
              <div className='mt-1'>
                <Field
                  type='text'
                  id='first-name'
                  name='first-name'
                  autoComplete='family-name'
                  className='block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                  onBlur={(event: FocusEvent<HTMLInputElement>) => {
                    shippingAddressUser.setFirstName(event.target.value);
                  }}
                />
              </div>
            </div>
            <div>
              <label htmlFor='last-name' className='block text-sm font-medium text-gray-700'>
                <FormattedMessage id='app.common.last_name' defaultMessage='First Name' />
              </label>
              <div className='mt-1'>
                <Field
                  type='text'
                  id='last-name'
                  name='last-name'
                  autoComplete='given-name'
                  className='block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                  onBlur={(event: FocusEvent<HTMLInputElement>) => {
                    shippingAddressUser.setLastName(event.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className='mt-6'>
            <label htmlFor='email-address' className='block text-sm font-medium text-gray-700'>
              <FormattedMessage id='app.common.email' defaultMessage='Email' />
            </label>
            <div className='mt-1'>
              <Field
                type='email'
                id='email-address'
                name='email-address'
                autoComplete='email'
                className='block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                onBlur={(event: FocusEvent<HTMLInputElement>) => {
                  shippingAddressUser.setEmail(event.target.value);
                }}
              />
            </div>
          </div>
        </section>

        <section aria-labelledby='shipping-heading' className='mt-10'>
          <h2 id='shipping-heading' className='text-lg font-medium text-gray-900'>
            <FormattedMessage id='app.account_page.addresses.shipping_address' defaultMessage='First Name' />
          </h2>

          <ShippingAddress />
        </section>

        <section aria-labelledby='billing-heading' className='mt-10'>
          <h2 id='billing-heading' className='text-lg font-medium text-gray-900'>
            <FormattedMessage id='app.common.billing_information' defaultMessage='Billing Information' />
          </h2>

          <div className='mt-6 mb-4 flex items-center'>
            <Field
              id='same-as-shipping'
              name='same-as-shipping'
              type='checkbox'
              className='h-4 w-4 border-gray-300 rounded text-main-400 focus:ring-main-500'
            />
            <div className='ml-2'>
              <label htmlFor='same-as-shipping' className='text-sm font-medium text-gray-900'>
                <FormattedMessage id='app.checkout.same_as_shipping' defaultMessage='Same as shipping information' />
              </label>
            </div>
          </div>

          {!values['same-as-shipping'] && (
            <>
              <div className='mt-6 grid grid-cols-2 gap-2'>
                <div>
                  <label htmlFor='billing-first-name' className='block text-sm font-medium text-gray-700'>
                    <FormattedMessage id='app.common.first_name' defaultMessage='First Name' />
                  </label>
                  <div className='mt-1'>
                    <Field
                      type='text'
                      id='billing-first-name'
                      name='billing-first-name'
                      autoComplete='family-name'
                      className='block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                      onBlur={(event: FocusEvent<HTMLInputElement>) => {
                        billingAddressUser.setFirstName(event.target.value);
                      }}
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor='billing-last-name' className='block text-sm font-medium text-gray-700'>
                    <FormattedMessage id='app.common.last_name' defaultMessage='Last Name' />
                  </label>
                  <div className='mt-1'>
                    <Field
                      type='text'
                      id='billing-last-name'
                      name='billing-last-name'
                      autoComplete='given-name'
                      className='block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                      onBlur={(event: FocusEvent<HTMLInputElement>) => {
                        billingAddressUser.setLastName(event.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className='mt-6 mb-4'>
                <label htmlFor='billing-email-address' className='block text-sm font-medium text-gray-700'>
                  <FormattedMessage id='app.common.email' defaultMessage='Email Address' />
                </label>
                <div className='mt-1'>
                  <Field
                    type='email'
                    id='billing-email-address'
                    name='billing-email-address'
                    autoComplete='email'
                    className='block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                    onBlur={(event: FocusEvent<HTMLInputElement>) => {
                      billingAddressUser.setEmail(event.target.value);
                    }}
                  />
                </div>
              </div>

              <BillingAddress />
            </>
          )}
        </section>

        <section aria-labelledby='shipping-rate-heading' className='mt-10'>
          <ShippingRate />
        </section>

        <section aria-labelledby='shipping-rate-heading' className='mt-10'>
          <PaymentMethods />
        </section>

        <div className='mt-10 pt-6 border-t border-gray-200 sm:flex sm:items-center sm:justify-between'>
          <button
            type='submit'
            className='w-full bg-main-400 border border-transparent rounded-md shadow-sm py-2 px-4 text-sm font-medium text-white hover:bg-main-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-main-500 sm:ml-6 sm:order-last sm:w-auto'
          >
            <FormattedMessage id='app.common.continue' defaultMessage='Continue' />
          </button>
          <p className='mt-4 text-center text-sm text-gray-500 sm:mt-0 sm:text-left'>
            <FormattedMessage
              id='app.payment.before_payment'
              defaultMessage="You won't be charged until the next step"
            />
          </p>
        </div>
      </div>
    </div>
  );
}
