import { AxiosResponse } from 'axios';
import { Backend } from '~/v2/utils/service';
import { AddressParamT, AddressT } from './address.type';

export const createNewAddress = async (body: AddressParamT): Promise<{ data: AddressT; message: string } | null> => {
  try {
    const result: AxiosResponse<{ data: AddressT; message: string }> = await Backend.vInstance.post(
      '/addresses/create?token=true',
      body
    );

    if (result.status === 200 && result.data) {
      return new Promise((resolve) => resolve(result.data));
    }
  } catch (error) {
    console.log(error);
  }

  return new Promise((resolve) => resolve(null));
};

export const updateAddress = async (
  id: number,
  body: AddressParamT
): Promise<{ data: AddressT; message: string } | null> => {
  try {
    const result: AxiosResponse<{ data: AddressT; message: string }> = await Backend.vInstance.post(
      `/addresses/${id}?token=true`,
      { ...body, _method: 'PUT' }
    );

    if (result.status === 200 && result.data) {
      return new Promise((resolve) => resolve(result.data));
    }
  } catch (error) {
    console.log(error);
  }

  return new Promise((resolve) => resolve(null));
};
