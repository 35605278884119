/**
 *
 * @description RegisterDialog.tsx
 * @author yikkok <yikok.yong@gmail.com>
 * @version 1.0.0
 * @since 13 November 2021
 *
 */

import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { message as AntMessage, Form, Input, Button } from 'antd';

import { AuthContext, AuthContextT } from '~/v2/context/AuthProvider';
import { LoginFormValues } from '~/v2/parts/auth/LoginForm';

export type SignUpFormValues = {
  password: string;
  email: string;
  firstname: string;
  lastname: string;
};

type Props = {
  open: boolean;
  onClose: () => void;
  onCancel: () => void;
};
export default function RegisterDialog({ open, onClose, onCancel }: Props) {
  const authCtx = useContext<Partial<AuthContextT>>(AuthContext);
  const intl = useIntl();

  const onFinish = async (values: SignUpFormValues) => {
    const { email, password, firstname, lastname } = values;
    await authCtx.onSignUp?.(email, password, firstname, lastname);
  };

  const onFinishFailed = () => {
    AntMessage.error({
      content: 'Something went wrong. We are alert to it. Please try again later.',
    });
  };

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as='div' className='fixed inset-0 z-10 overflow-y-auto bg-gray-700 bg-opacity-80' onClose={onClose}>
        <div className='min-h-screen px-4 text-center'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0' />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className='inline-block h-screen align-middle' aria-hidden='true'>
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 scale-95'
            enterTo='opacity-100 scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 scale-100'
            leaveTo='opacity-0 scale-95'
          >
            <div className='inline-block w-full max-w-sm md:max-w-md lg:max-w-lg p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl'>
              <Dialog.Title as='h3' className='text-xl font-bold text-center leading-6 text-gray-900 pb-4'>
                <FormattedMessage id='app.common.register' defaultMessage='Register' />
              </Dialog.Title>

              <div className='auth-form'>
                <Form layout='vertical' name='register' onFinish={onFinish} onFinishFailed={onFinishFailed}>
                  <Form.Item
                    label={intl.formatMessage({ id: 'app.common.first_name', defaultMessage: 'First Name' })}
                    name='firstname'
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label={intl.formatMessage({ id: 'app.common.last_name', defaultMessage: 'Last Name' })}
                    name='lastname'
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label={intl.formatMessage({ id: 'app.common.email', defaultMessage: 'Email address' })}
                    name='email'
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'app.validate.email',
                          defaultMessage: 'Please input valid email!',
                        }),
                        type: 'email',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label={intl.formatMessage({ id: 'app.common.password', defaultMessage: 'Password' })}
                    name='password'
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'app.validate.password',
                          defaultMessage: 'Please input your password!',
                        }),
                      },
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                  <Form.Item className='form-submit'>
                    <button type='submit' className='bg-main-300 py-3 w-full text-white font-semibold'>
                      <FormattedMessage id='app.common.register' defaultMessage='Register' />
                    </button>

                    <div className='text-center mt-8'>
                      <a
                        href='#'
                        onClick={(e) => {
                          e.preventDefault();
                          authCtx.openLoginModal();
                        }}
                      >
                        <FormattedMessage
                          id='app.common.login_here'
                          defaultMessage='Already have an account? Login here'
                        />
                      </a>
                    </div>
                  </Form.Item>
                </Form>
              </div>

              <div className='mt-2 flex justify-end'>
                <button
                  type='button'
                  className='inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-red-500 border border-transparent rounded-md hover:bg-red-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500 mr-2'
                  onClick={onCancel}
                >
                  Close
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
