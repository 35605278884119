/**
 *
 * @description AddressProvider.tsx
 * @author yikkok <yikok.yong@gmail.com>
 * @version 1.0.0
 * @since 13 November 2021
 *
 */

import { Dialog, Transition } from '@headlessui/react';
import { ChangeEvent, createContext, Fragment, useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { AddressT } from '~/@customTypes/addresses.type';
import { ShippingRateT } from '~/@customTypes/cart.type';
import { ChildrenT } from '~/@customTypes/generic';
import { createNewAddress } from '../modules/address/address.service';
import { billingAddressUser, shippingAddressUser } from '../parts/checkout/CheckoutForm';
import { fetchBillingAddresses, saveAddress } from '../services/addresses.services';
import { STATE_IN_MALAYSIA } from '../utils/states';
import { AuthContext } from './AuthProvider';
import { CartContext } from './CartProvider';
import { LocaleContext, LocaleContextT } from './LocaleProvider';

export type AddressContextT = {
  addresses: Array<AddressT>;
  rates: Array<ShippingRateT>;
  selectedShipping: number;
  selectedBilling: number;
  onUpdateSelected: (id: number, type: 'billing' | 'shipping') => void;
  onUpdateSameAsShiping: (value: boolean) => void;
  refresh: () => void;
  closeModal: () => void;
  openModal: () => void;
};
export const AddressContext = createContext<Partial<AddressContextT>>({});

type Props = ChildrenT;

export default function AddressProvider({ children }: Props) {
  const authCtx = useContext(AuthContext);
  const cartCtx = useContext(CartContext);
  const localeCtx = useContext<LocaleContextT>(LocaleContext);

  const [_fetchShipping, setFetchShipping] = useState(true);
  const [addresses, setAddresses] = useState<Array<AddressT>>([]);
  const [rates, setRates] = useState<Array<ShippingRateT>>([]);
  const [sameAsShipping, setSameAsShipping] = useState(true);

  const [selectedShipping, setSelectedShipping] = useState(-1);
  const [selectedBilling, setSelectedBilling] = useState(-1);

  const [isOpen, setIsOpen] = useState(false);

  const [address, setAddress] = useState({
    street: '',
    city: '',
    state: '',
    country: 'MY',
    postcode: '',
    phone: '',
  });

  useEffect(() => {
    if (isOpen) {
      setAddress({
        street: '',
        city: '',
        state: '',
        country: 'MY',
        postcode: '',
        phone: '',
      });
    }
  }, [isOpen]);

  useEffect(() => {
    if (authCtx.isLoggedIn) {
      (async () => {
        const result = await fetchBillingAddresses();

        if (result && result.data) {
          setAddresses(result.data);

          if (result.data.length > 0) {
            setSelectedShipping(result.data[0].id);
            setSelectedBilling(result.data[0].id);
          }
        }
      })();
    }
  }, [authCtx.isLoggedIn]);

  useEffect(() => {
    if (selectedShipping !== -1 && cartCtx.cart !== null) {
      setFetchShipping((prev) => {
        if (prev) {
          onUpdateSelected(selectedShipping, 'shipping');
          return false;
        }
        return false;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedShipping, cartCtx.cart, localeCtx.locale]);

  const onUpdateSelected = async (addressId: number, type: 'billing' | 'shipping') => {
    if (type === 'shipping') {
      setSelectedShipping(addressId);

      const shippingAddress = addresses.find((item) => item.id === addressId);

      const billingId = sameAsShipping ? addressId : selectedBilling;
      const billingAddress = addresses.find((item) => item.id === billingId);

      if (shippingAddress && billingAddress) {
        const shippingUser = {
          first_name: shippingAddressUser.firstName,
          last_name: shippingAddressUser.lastName,
          email: shippingAddressUser.email,
        };

        const billingUser = sameAsShipping
          ? shippingUser
          : {
              first_name: billingAddressUser.firstName,
              last_name: billingAddressUser.lastName,
              email: billingAddressUser.email,
            };

        const result = await saveAddress(
          {
            address: shippingAddress,
            user: shippingUser,
          },
          { address: billingAddress, user: billingUser },
          sameAsShipping ? true : false,
          localeCtx.locale
        );

        if (result && result.data) {
          setRates(result.data.rates);
          cartCtx.setCart?.(result.data?.cart || {});

          if (result.data?.cart?.selected_shipping_rate) {
            cartCtx.onUpdateSelectedRate?.(
              result.data?.cart?.selected_shipping_rate?.id,
              result.data?.cart?.selected_shipping_rate?.method,
              localeCtx.locale
            );
          } else {
            if (result.data?.rates?.length > 0 && result.data.rates[0].rates?.length > 0) {
              cartCtx.onUpdateSelectedRate?.(
                result.data.rates[0].rates[0].id,
                result.data.rates[0].rates[0].method,
                localeCtx.locale
              );
            }
          }
        }
      }
    }

    if (type === 'billing') {
      setSelectedBilling(addressId);

      const shippingAddress = addresses.find((item) => item.id === selectedShipping);
      const billingAddress = addresses.find((item) => item.id === addressId);

      if (shippingAddress && billingAddress) {
        const shippingUser = {
          first_name: shippingAddressUser.firstName,
          last_name: shippingAddressUser.lastName,
          email: shippingAddressUser.email,
        };

        const billingUser = sameAsShipping
          ? shippingUser
          : {
              first_name: billingAddressUser.firstName,
              last_name: billingAddressUser.lastName,
              email: billingAddressUser.email,
            };

        const result = await saveAddress(
          {
            address: shippingAddress,
            user: shippingUser,
          },
          { address: billingAddress, user: billingUser },
          sameAsShipping ? true : false,
          localeCtx.locale
        );

        if (result && result.data) {
          setRates(result.data.rates);
          cartCtx.setCart?.(result.data?.cart || {});

          if (result.data?.cart?.selected_shipping_rate) {
            cartCtx.onUpdateSelectedRate?.(
              result.data?.cart?.selected_shipping_rate?.id,
              result.data?.cart?.selected_shipping_rate?.method,
              localeCtx.locale
            );
          } else {
            if (result.data?.rates?.length > 0 && result.data.rates[0].rates?.length > 0) {
              cartCtx.onUpdateSelectedRate?.(
                result.data.rates[0].rates[0].id,
                result.data.rates[0].rates[0].method,
                localeCtx.locale
              );
            }
          }
        }
      }
    }
  };

  const onUpdateSameAsShiping = (value: boolean) => {
    setSameAsShipping(value);
  };

  const refresh = async () => {
    const result = await fetchBillingAddresses();

    if (result && result.data) {
      setAddresses(result.data);

      if (result.data?.length > 0) {
        setSelectedShipping(result.data[0].id);
        setSelectedBilling(result.data[0].id);

        onUpdateSelected(result.data[0].id, 'shipping');
      }
    }
  };

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const onInput = (e: ChangeEvent<HTMLInputElement>) => {
    const key = e.target.name;
    const value = e.target.value;

    setAddress((prev) => ({ ...prev, [key]: value }));
  };

  const onChangeSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    setAddress((prev) => ({ ...prev, country: e.target.value }));
  };

  const onChangeSelectState = (e: ChangeEvent<HTMLSelectElement>) => {
    setAddress((prev) => ({ ...prev, state: e.target.value }));
  };

  const onSubmit = async () => {
    for (const key in address) {
      if (Object.prototype.hasOwnProperty.call(address, key)) {
        const element = address[key];

        if (!element) {
          alert('All fields are required');
          break;
        }
      }
    }

    let country_name = 'Malaysia';

    if (address.country === 'SG') {
      country_name = 'Singapore';
    }

    const result = await createNewAddress({
      address1: [address.street],
      city: address.city,
      state: address.state,
      phone: address.phone,
      postcode: address.postcode,
      country: address.country,
      country_name: country_name,
    });

    if (result && result.data) {
      alert('Successfully created new address');
      refresh();
      closeModal();
    }
  };

  return (
    <AddressContext.Provider
      value={{
        addresses,
        rates,
        selectedShipping,
        selectedBilling,
        onUpdateSelected,
        onUpdateSameAsShiping,
        refresh,
        openModal,
        closeModal,
      }}
    >
      {children}

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog static open={isOpen} as='div' className='fixed inset-0 z-10 overflow-y-auto' onClose={closeModal}>
          <div className='min-h-screen px-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-50' />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className='inline-block h-screen align-middle' aria-hidden='true'>
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <div className='inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl'>
                <Dialog.Title as='h3' className='text-lg font-medium leading-6 text-gray-900'>
                  <FormattedMessage id='app.address.create' defaultMessage='Create New Address' />
                </Dialog.Title>
                <div className='mt-2'>
                  <div className='mb-4'>
                    <label htmlFor='street-1' className='block text-sm font-medium text-gray-700'>
                      <FormattedMessage id='app.common.street_address' defaultMessage='Street' />
                    </label>
                    <div className='mt-1'>
                      <input
                        type='text'
                        name='street'
                        className='shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'
                        placeholder='e.g., Jalan Bangsar'
                        value={address.street}
                        onInput={onInput}
                      />
                    </div>
                  </div>
                  <div className='mb-4'>
                    <label htmlFor='city' className='block text-sm font-medium text-gray-700'>
                      <FormattedMessage id='app.common.city' defaultMessage='City' />
                    </label>
                    <div className='mt-1'>
                      <input
                        type='text'
                        name='city'
                        className='shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'
                        placeholder='e.g., Kuala Lumpur'
                        value={address.city}
                        onInput={onInput}
                      />
                    </div>
                  </div>
                  <div className='mb-4'>
                    <label htmlFor='postcode' className='block text-sm font-medium text-gray-700'>
                      <FormattedMessage id='app.common.postcode' defaultMessage='Postcode' />
                    </label>
                    <div className='mt-1'>
                      <input
                        type='text'
                        name='postcode'
                        className='shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'
                        placeholder='e.g., 56200'
                        value={address.postcode}
                        onInput={onInput}
                      />
                    </div>
                  </div>
                  <div className='mb-4'>
                    <label htmlFor='state' className='block text-sm font-medium text-gray-700'>
                      <FormattedMessage id='app.common.state' defaultMessage='State' />
                    </label>
                    <div className='mt-1'>
                      <select
                        id='state'
                        name='state'
                        className='mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md'
                        value={address.state}
                        onInput={onChangeSelectState}
                      >
                        <option value=''>Select State</option>
                        {STATE_IN_MALAYSIA.map((item) => (
                          <option value={item.title} key={item.value}>
                            {item.title}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className='mb-4'>
                    <label htmlFor='country' className='block text-sm font-medium text-gray-700'>
                      <FormattedMessage id='app.common.country' defaultMessage='Country' />
                    </label>
                    <div className='mt-1'>
                      <select
                        id='country'
                        name='country'
                        className='mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md'
                        value={address.country}
                        onInput={onChangeSelect}
                      >
                        <option value='MY'>Malaysia</option>
                        <option value='SG'>Singapore</option>
                      </select>
                    </div>
                  </div>

                  <div className='mb-4'>
                    <label htmlFor='phone' className='block text-sm font-medium text-gray-700'>
                      <FormattedMessage id='app.common.phone_number' defaultMessage='Phone Number' />
                    </label>
                    <div className='mt-1'>
                      <input
                        type='text'
                        name='phone'
                        className='shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'
                        placeholder='e.g., 012 345 6789'
                        value={address.phone}
                        onInput={onInput}
                      />
                    </div>
                  </div>
                </div>

                <div className='mt-4 flex justify-center'>
                  <button
                    type='button'
                    className='inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-main-400 border border-transparent rounded-md hover:bg-main-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-main-500'
                    onClick={onSubmit}
                  >
                    <FormattedMessage id='app.common.confirm' defaultMessage='Confirm' />
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </AddressContext.Provider>
  );
}
