/**
 *
 * @description PaymentMethodsProvider.tsx
 * @author yikkok <yikok.yong@gmail.com>
 * @version 1.0.0
 * @since 13 November 2021
 *
 */

import { createContext, Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ChildrenT } from '~/@customTypes/generic';
import { PaymentMethodT } from '~/@customTypes/payment.type';
import { savePaymentMethod } from '../services/payment.services';

export type PaymentMethodsContextT = {
  paymentMethods: Array<PaymentMethodT>;
  setPaymentMethods: Dispatch<SetStateAction<Array<PaymentMethodT>>>;
  selectedPaymentMethod: string;
  setSelectedPaymentMethod: Dispatch<SetStateAction<string>>;
};
export const PaymentMethodsContext = createContext<Partial<PaymentMethodsContextT>>({});

type Props = ChildrenT;
export default function PaymentMethodsProvider({ children }: Props) {
  const [paymentMethods, setPaymentMethods] = useState<Array<PaymentMethodT>>([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('eghl');

  useEffect(() => {
    if (paymentMethods.length > 0) {
      savePaymentMethod('eghl');
    }
  }, [paymentMethods]);

  return (
    <PaymentMethodsContext.Provider
      value={{ paymentMethods, setPaymentMethods, selectedPaymentMethod, setSelectedPaymentMethod }}
    >
      {children}
    </PaymentMethodsContext.Provider>
  );
}
