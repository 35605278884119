/**
 *
 * @description BillingAddress.tsx
 * @author yikkok <yikok.yong@gmail.com>
 * @version 1.0.0
 * @since 13 November 2021
 *
 */

import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { RadioGroup } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/solid';

import { classNames } from '~/v2/utils/general';
import { AddressContext, AddressContextT } from '~/v2/context/AddressProvider';

export default function BillingAddress() {
  const { addresses, selectedBilling, onUpdateSelected } = useContext<Partial<AddressContextT>>(AddressContext);

  return (
    <RadioGroup
      value={selectedBilling}
      onChange={(value) => {
        if (value) {
          onUpdateSelected?.(value, 'billing');
        }
      }}
    >
      <RadioGroup.Label className='text-base font-medium text-gray-900'>
        <FormattedMessage id='app.address.select_billing' defaultMessage='Select Billing Address' />
      </RadioGroup.Label>

      <div className='mt-4 grid grid-cols-1 gap-y-6'>
        {addresses?.map((address) => (
          <RadioGroup.Option
            key={address.id}
            value={address.id}
            className={({ checked, active }) =>
              classNames(
                checked ? 'border-transparent' : 'border-gray-300',
                active ? 'ring-2 ring-main-400' : '',
                'relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none'
              )
            }
          >
            {({ checked, active }) => (
              <>
                <div className='flex-1 flex'>
                  <div className='flex flex-col'>
                    <RadioGroup.Label as='span' className='block text-sm font-medium text-gray-900'>
                      {address.state}, {address.country}
                    </RadioGroup.Label>
                    <RadioGroup.Description as='span' className='mt-1 flex items-center text-sm text-gray-500'>
                      {address.address1.join(' ')}
                    </RadioGroup.Description>
                    <RadioGroup.Description as='span' className='mt-6 text-sm font-medium text-gray-900'>
                      {address.postcode} {address.city}
                    </RadioGroup.Description>
                  </div>
                </div>
                <CheckCircleIcon
                  className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-main-400')}
                  aria-hidden='true'
                />
                <div
                  className={classNames(
                    active ? 'border' : 'border-2',
                    checked ? 'border-main-400' : 'border-transparent',
                    'absolute -inset-px rounded-lg pointer-events-none'
                  )}
                  aria-hidden='true'
                />
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
}
