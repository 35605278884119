export const STATE_IN_MALAYSIA = [
  { title: 'Kedah', value: 'Kedah' },
  { title: 'Kuala Lumpur', value: 'Kuala Lumpur' },
  { title: 'Perlis', value: 'Perlis' },
  { title: 'Pulau Penang', value: 'Pulau Penang' },
  { title: 'Perak', value: 'Perak' },
  { title: 'Selangor', value: 'Selangor' },
  { title: 'Putrajaya', value: 'Putrajaya' },
  { title: 'Melacca', value: 'Melacca' },
  { title: 'Negeri Sembilan', value: 'Negeri Sembilan' },
  { title: 'Johor', value: 'Johor' },
  { title: 'Pahang', value: 'Pahang' },
  { title: 'Terengganu', value: 'Terengganu' },
  { title: 'Kelantan', value: 'Kelantan' },
  { title: 'Sabah', value: 'Sabah' },
  { title: 'Sarawak', value: 'Sarawak' },
  { title: 'Singapore', value: 'Singapore' },
];
