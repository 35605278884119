/**
 *
 * @description CartProvider.tsx
 * @author yikkok <yikok.yong@gmail.com>
 * @version 1.0.0
 * @since 31 October 2021
 *
 */

import { createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';

import { ChildrenT, JsonGeneric, ResponseT } from '~/@customTypes/generic';
import { BundleOptionT, ProductT } from '~/@customTypes/product.type';
import { CartT } from '~/@customTypes/cart.type';
import { axiosInstance } from '../utils/service';
import { message } from 'antd';
import { LoaderContext, LoaderContextT } from './LoaderProvider';
import { saveShipping } from '../services/addresses.services';
import { PaymentMethodsContext, PaymentMethodsContextT } from './PaymentMethodsProvider';
import { LocaleContext, LocaleContextT } from './LocaleProvider';

export type CartContextT = {
  cart: Partial<CartT> | null;
  setCart: Dispatch<SetStateAction<Partial<CartT> | null>>;
  fetchCart: boolean;
  selectedRate: number;
  setFetchCart: Dispatch<SetStateAction<boolean>>;
  onAddSimpleProductToCart: (item: ProductT, quantity: number) => Promise<void>;
  onAddBundleProductToCart: (product: { item: ProductT; quantity: number }, bundle: BundleOptionT) => Promise<void>;
  onEmptyCart: () => void;
  onUpdateCart: (productId: number, quantity: number) => void;
  onRemoveCartItem: (productId: number) => void;
  onUpdateSelectedRate: (rateId: number, method?: string | undefined, locale?: string | undefined) => void;
  resetCart: () => void;
  refreshCart: () => void;
};

export const CartContext = createContext<Partial<CartContextT>>({});

type Props = ChildrenT;

export default function CartProvider({ children }: Props) {
  const [cart, setCart] = useState<Partial<CartT> | null>(null);
  const [fetchCart, setFetchCart] = useState(true);
  const [selectedRate, setSelectedRate] = useState(-1);

  const loaderCtx = useContext<LoaderContextT>(LoaderContext);
  const methodCtx = useContext<Partial<PaymentMethodsContextT>>(PaymentMethodsContext);
  const localeCtx = useContext<LocaleContextT>(LocaleContext);

  useEffect(() => {
    if (fetchCart) {
      (async () => {
        const response: void | AxiosResponse<ResponseT<CartT>> = await axiosInstance
          .get('/checkout/cart', {
            params: {
              locale: localeCtx.locale,
            },
          })
          .catch((err) => {
            console.log(err);
          });

        if (response && response.status === 200) {
          setCart(response.data?.data || {});
          setSelectedRate(response.data?.data?.selected_shipping_rate?.id || -1);
          setFetchCart(false);
        }
      })();
    }
  }, [fetchCart, localeCtx.locale]);

  const onAddSimpleProductToCart = async (item: ProductT, quantity: number) => {
    loaderCtx.setLoading(true);
    const response: void | AxiosResponse<ResponseT<CartT>> = await axiosInstance
      .post(`/checkout/cart/add/${item.id}`, {
        product_id: item.id,
        quantity,
      })
      .catch((err) => {
        console.log(err);
        loaderCtx.setLoading(false);
      });

    if (response && response.status === 200) {
      setCart((prev) => ({ ...prev, ...response.data.data }));
      setSelectedRate(response.data?.data?.selected_shipping_rate?.id || -1);
      message.success('Successfully added to cart');
    }

    loaderCtx.setLoading(false);
  };

  const onAddBundleProductToCart = async (product: { item: ProductT; quantity: number }, bundle: BundleOptionT) => {
    loaderCtx.setLoading(true);

    const option: JsonGeneric<number> = {};
    bundle.products.forEach((optProduct, idx) => {
      option[`index_${idx}`] = optProduct.id;
    });

    const response: void | AxiosResponse<ResponseT<CartT>> = await axiosInstance
      .post(`/checkout/cart/add/${product.item.id}`, {
        product_id: product.item.id,
        quantity: product.quantity,
        bundle_options: {
          [bundle.id]: option,
        },
        bundle_option_qty: {
          [bundle.id]: 1,
        },
      })
      .catch((err) => {
        console.log(err);
        loaderCtx.setLoading(false);
      });

    if (response && response.status === 200) {
      setCart((prev) => ({ ...prev, ...response.data.data }));
      setSelectedRate(response.data?.data?.selected_shipping_rate?.id || -1);
      message.success('Successfully added to cart');
    }

    loaderCtx.setLoading(false);
  };

  const onEmptyCart = async () => {
    loaderCtx.setLoading(true);
    const response: void | AxiosResponse<ResponseT<{ message: string; data: null }>> = await axiosInstance
      .get('checkout/cart/empty')
      .catch((err) => {
        console.log(err);
        loaderCtx.setLoading(false);
      });

    if (response && response.status === 200) {
      setCart({});
      setSelectedRate(-1);
      message.success({
        content: response.data.message || 'Cart removed successfully',
      });
    }
    loaderCtx.setLoading(false);
  };

  const onUpdateCart = async (productId: number, quantity: number) => {
    loaderCtx.setLoading(true);
    const response: void | AxiosResponse<ResponseT<CartT>> = await axiosInstance
      .put('/checkout/cart/update/', {
        qty: {
          [`${productId}`]: quantity,
        },
      })
      .catch((err) => {
        console.log(err);
        loaderCtx.setLoading(false);
      });

    if (response && response.status === 200) {
      setCart((prev) => ({ ...prev, ...response.data.data }));
      setSelectedRate(response.data?.data?.selected_shipping_rate?.id || -1);
      message.success({
        content: response.data.message || 'Cart removed successfully',
      });
    }

    loaderCtx.setLoading(false);
  };

  const onRemoveCartItem = async (productId: number) => {
    loaderCtx.setLoading(true);
    const response: void | AxiosResponse<ResponseT<CartT>> = await axiosInstance
      .get(`/checkout/cart/remove-item/${productId}`)
      .catch((err) => {
        console.log(err);
        loaderCtx.setLoading(false);
      });

    if (response && response.status === 200) {
      if (!response.data.data) {
        setCart(null);
      } else {
        setCart((prev) => ({ ...prev, ...response.data.data }));
      }

      setSelectedRate(response.data?.data?.selected_shipping_rate?.id || -1);
      message.success({
        content: response.data.message || 'Cart item removed successfully',
      });
    }

    loaderCtx.setLoading(false);
  };

  const onUpdateSelectedRate = async (value: number, method = '', locale = 'en') => {
    setSelectedRate(value);

    if (method) {
      const result = await saveShipping(method, locale);

      if (result && result.data) {
        setFetchCart(true);
        result.data.methods.sort((a, b) => {
          if (a.sort < b.sort) {
            return -1;
          }

          if (a.sort > b.sort) {
            return 1;
          }

          return 0;
        });

        methodCtx.setPaymentMethods?.(result.data.methods);
      }
    }
  };

  const resetCart = () => {
    setCart(null);
  };

  const refreshCart = async () => {
    const response: void | AxiosResponse<ResponseT<CartT>> = await axiosInstance
      .get('/checkout/cart', {
        params: {
          locale: localeCtx.locale,
        },
      })
      .catch((err) => {
        console.log(err);
      });

    if (response && response.status === 200) {
      setCart(response.data?.data || {});
      setFetchCart(false);
    }
  };

  return (
    <CartContext.Provider
      value={{
        cart,
        setCart,
        fetchCart,
        selectedRate,
        onAddSimpleProductToCart,
        onAddBundleProductToCart,
        onEmptyCart,
        setFetchCart,
        onUpdateCart,
        onRemoveCartItem,
        onUpdateSelectedRate,
        resetCart,
        refreshCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
}
