/**
 *
 * @description useModal.ts Custom Hooks
 * @author yikkok <yikok.yong@gmail.com>
 * @version 1.0.0
 * @since 13 November 2021
 *
 */

import { useState } from 'react';

type ExtraT = {
  [key: string]: string | number;
};
export type ModalHookT<T = ExtraT> = {
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
  extra: Partial<T>;
  setExtraInfo: <T = ExtraT>(value: T) => void;
};
export default function useModal(): ModalHookT {
  const [open, setOpen] = useState(false);
  const [extra, setExtra] = useState<Partial<ExtraT>>({});

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const setExtraInfo = <T = ExtraT>(info: T): void => {
    setExtra(info);
  };

  return { open, onOpen, onClose, extra, setExtraInfo };
}
