/**
 *
 * @description ShippingRate.tsx
 * @author yikkok <yikok.yong@gmail.com>
 * @version 1.0.0
 * @since 13 November 2021
 *
 */

import { RadioGroup } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/solid';
import { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { AddressContext, AddressContextT } from '~/v2/context/AddressProvider';
import { CartContext, CartContextT } from '~/v2/context/CartProvider';
import { LocaleContext, LocaleContextT } from '~/v2/context/LocaleProvider';
import { classNames } from '~/v2/utils/general';

export default function ShippingRate() {
  const { rates } = useContext<Partial<AddressContextT>>(AddressContext);
  const { selectedRate, onUpdateSelectedRate } = useContext<Partial<CartContextT>>(CartContext);
  const { locale } = useContext<LocaleContextT>(LocaleContext);

  useEffect(() => {
    if (rates && rates.length) {
      onUpdateSelectedRate?.(rates[0].rates?.[0].id, rates[0].rates?.[0].method, locale);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rates, locale]);

  if (rates?.length === 0) {
    return null;
  }

  return (
    <RadioGroup
      value={selectedRate}
      onChange={(value) => {
        if (value) {
          rates?.every((rate) => {
            const matched = rate.rates.find((item) => item.id === value);

            if (matched) {
              onUpdateSelectedRate?.(value, matched.method, locale);
              return false;
            }

            return true;
          });
        }
      }}
    >
      <RadioGroup.Label className='text-lg font-medium text-gray-900'>
        <FormattedMessage id='app.address.select_shipping_method' defaultMessage='Select Shipping Method' />
      </RadioGroup.Label>

      <div className='mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4'>
        {rates?.map((rate, rateIdx) => {
          return (
            <div key={rateIdx}>
              <p className='p-4 text-lg'>{rate.carrier_title}</p>
              {rate.rates.map((item) => (
                <RadioGroup.Option
                  key={item.id}
                  value={item.id}
                  className={({ checked, active }) =>
                    classNames(
                      checked ? 'border-transparent' : 'border-gray-300',
                      active ? 'ring-2 ring-main-400' : '',
                      'relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none'
                    )
                  }
                >
                  {({ checked, active }) => (
                    <>
                      <div className='flex-1 flex'>
                        <div className='flex flex-col'>
                          <RadioGroup.Label as='span' className='block text-sm font-medium text-gray-900'>
                            {item.method_title}
                          </RadioGroup.Label>
                          <RadioGroup.Description as='span' className='mt-1 flex items-center text-sm text-gray-500'>
                            {item.method_description}
                          </RadioGroup.Description>
                          <RadioGroup.Description as='span' className='mt-6 text-sm font-medium text-gray-900'>
                            {item.formated_price}
                          </RadioGroup.Description>
                        </div>
                      </div>
                      <CheckCircleIcon
                        className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-main-400')}
                        aria-hidden='true'
                      />
                      <div
                        className={classNames(
                          active ? 'border' : 'border-2',
                          checked ? 'border-main-400' : 'border-transparent',
                          'absolute -inset-px rounded-lg pointer-events-none'
                        )}
                        aria-hidden='true'
                      />
                    </>
                  )}
                </RadioGroup.Option>
              ))}
            </div>
          );
        })}
      </div>
    </RadioGroup>
  );
}
