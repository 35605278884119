import { AxiosResponse } from 'axios';
import { AddressT } from '~/@customTypes/addresses.type';
import { CartT, ShippingRateT } from '~/@customTypes/cart.type';
import { ResponseT } from '~/@customTypes/generic';
import { PaymentMethodT } from '~/@customTypes/payment.type';
import { axiosInstanceWithoutProxy } from '../utils/service';

export const fetchBillingAddresses = async (): Promise<{ data: Array<AddressT> } | null> => {
  try {
    const result: AxiosResponse<ResponseT<Array<AddressT>>> = await axiosInstanceWithoutProxy.get(
      '/addresses?token=true'
    );

    if (result && result.status === 200 && result.data) {
      return new Promise((resolve) => resolve(result.data));
    }
  } catch (error) {
    console.log(error);
  }

  return new Promise((resolve) => resolve(null));
};

type SaveAddressUserT = {
  first_name: string;
  last_name: string;
  email: string;
};

type SaveAddressT = {
  address: AddressT;
  user: SaveAddressUserT;
};
export const saveAddress = async (
  { address: shipping, user: shippingUser }: SaveAddressT,
  { address: billing, user: billingUser }: SaveAddressT,
  sameAsShipping = true,
  locale = 'en'
): Promise<{ data: { cart: CartT; rates: Array<ShippingRateT> } } | null> => {
  try {
    const result: AxiosResponse<ResponseT<{ cart: CartT; rates: Array<ShippingRateT> }>> =
      await axiosInstanceWithoutProxy.post('/checkout/save-address', {
        billing: {
          address1: billing.address1,
          use_for_shipping: sameAsShipping,
          first_name: billingUser.first_name,
          last_name: billingUser.last_name,
          email: billingUser.email,
          address_id: billing.id,
        },
        shipping: {
          address1: shipping.address1,
          first_name: shippingUser.first_name,
          last_name: shippingUser.last_name,
          email: shippingUser.email,
          address_id: shipping.id,
        },
        locale,
      });

    if (result && result.status === 200 && result.data) {
      return new Promise((resolve) => resolve(result.data));
    }
  } catch (error) {
    console.log(error);
  }

  return new Promise((resolve) => resolve(null));
};

export const saveShipping = async (
  method: string,
  locale = 'en'
): Promise<{ data: { cart: CartT; methods: Array<PaymentMethodT> } } | null> => {
  try {
    const result: void | AxiosResponse<ResponseT<{ cart: CartT; methods: Array<PaymentMethodT> }>> =
      await axiosInstanceWithoutProxy.post('/checkout/save-shipping?token=true', {
        shipping_method: method,
        locale,
      });
    if (result && result.status === 200 && result.data) {
      return new Promise((resolve) => resolve(result.data));
    }
  } catch (error) {
    console.log(error);
  }

  return new Promise((resolve) => resolve(null));
};
