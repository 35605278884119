import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { IntlProvider } from 'react-intl';
import type { AppProps /*, AppContext */ } from 'next/app';

import '../styles/antd.less';
import '../styles/styles.scss';
import '../v2/global.css';
import 'react-multi-carousel/lib/styles.css';

import EN from '../content/locale/en.json';
import CN from '../content/locale/cn.json';

import AuthProvider from '~/v2/context/AuthProvider';
import LocaleProvider from '~/v2/context/LocaleProvider';
import ProductsProvider from '~/v2/context/ProductsProvider';
import ConfigProvider from '~/v2/context/ConfigProvider';
import CategoriesProvider from '~/v2/context/CategoriesProvider';
import CartProvider from '~/v2/context/CartProvider';
import LoaderProvider from '~/v2/context/LoaderProvider';
import AddressProvider from '~/v2/context/AddressProvider';
import PaymentMethodsProvider from '~/v2/context/PaymentMethodsProvider';
import AppProvider from '~/v2/context/AppProvider';
import SearchProvider from '~/v2/context/SearchProvider';

const App = ({ Component, pageProps }: AppProps) => {
  const router = useRouter();
  const { locale, defaultLocale } = router;

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement?.removeChild(jssStyles);
    }
  }, []);

  let messages = EN;

  if (locale === 'cn') {
    messages = CN;
  }

  return (
    <IntlProvider locale={locale as string} defaultLocale={defaultLocale} messages={messages}>
      <LoaderProvider>
        <LocaleProvider>
          <ConfigProvider>
            <AppProvider>
              <AuthProvider>
                <CategoriesProvider>
                  <ProductsProvider>
                    <PaymentMethodsProvider>
                      <CartProvider>
                        <AddressProvider>
                          <SearchProvider>
                            <Component {...pageProps} />
                          </SearchProvider>
                        </AddressProvider>
                      </CartProvider>
                    </PaymentMethodsProvider>
                  </ProductsProvider>
                </CategoriesProvider>
              </AuthProvider>
            </AppProvider>
          </ConfigProvider>
        </LocaleProvider>
      </LoaderProvider>
    </IntlProvider>
  );
};

export default App;
