/**
 *
 * @description ProductsProvider.tsx
 * @author yikkok <yikok.yong@gmail.com>
 * @version 1.0.0
 * @since 29 October 2021
 *
 */

import { useRouter } from 'next/router';
import { createContext, useContext } from 'react';
import { ChildrenT } from '~/@customTypes/generic';
import useProducts, { ProductHookT } from '../hooks/useProducts';
import { ConfigContext, ConfigContextT } from './ConfigProvider';
import { LocaleContext, LocaleContextT } from './LocaleProvider';

export type ProductsContextT = {
  newProducts: ProductHookT;
  all: ProductHookT;
  featured: ProductHookT;
};
export const ProductsContext = createContext<Partial<ProductsContextT>>({});

type Props = ChildrenT;

export default function ProductsProvider({ children }: Props) {
  const router = useRouter();

  const { locale } = useContext<LocaleContextT>(LocaleContext);
  const { currency } = useContext<ConfigContextT>(ConfigContext);

  const newProducts = useProducts({ type: 'new', locale, currency });
  const all = useProducts({ type: 'default', limit: 12, page: Number(router.query?.page) || 1, locale, currency });
  const featured = useProducts({ type: 'featured', locale, currency });

  return <ProductsContext.Provider value={{ all, featured, newProducts }}>{children}</ProductsContext.Provider>;
}
