/**
 *
 * @description useLoading.tsx Custom Hooks
 * @author yikkok <yikok.yong@gmail.com>
 * @version 1.0.0
 * @since 20 January 2022
 *
 */

import * as React from 'react';

export type UseLoadingT = {
  onStartLoading: () => void;
  onStopLoading: () => void;
  loading: boolean;
};
export default function useLoading(): UseLoadingT {
  const [loading, setLoading] = React.useState(false);

  const onStartLoading = () => {
    setLoading(true);
  };

  const onStopLoading = () => {
    setLoading(false);
  };

  return { onStartLoading, onStopLoading, loading };
}
