/**
 *
 * @description useProducts.ts Custom Hooks
 * @author yikkok <yikok.yong@gmail.com>
 * @version 1.0.0
 * @since 30 October 2021
 *
 */

import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { PaginateMetaT, PaginateResponseT } from '~/@customTypes/generic';
import { ProductT } from '~/@customTypes/product.type';
import { axiosInstanceWithoutProxy } from '../utils/service';

export type ProductHookT = {
  products?: Partial<Array<ProductT>>;
  loading: boolean;
  pagination: Partial<PaginateMetaT>;
};

type Props = {
  type?: 'default' | 'new' | 'featured' | undefined;
  limit?: number | undefined;
  categoryId?: number | undefined;
  page?: number | undefined;
  locale?: string | undefined;
  currency?: string | undefined;
};
export default function useProducts({
  type = 'default',
  page: currentPage = 1,
  limit = 10,
  locale = 'en',
  currency = undefined,
}: Props): ProductHookT {
  const [products, setProducts] = useState<Partial<Array<ProductT>>>([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState<Partial<PaginateMetaT>>({});

  useEffect(() => {
    if (type === 'default') {
      (async () => {
        setLoading(true);
        const productResponse: void | AxiosResponse<PaginateResponseT<Array<ProductT>>> =
          await axiosInstanceWithoutProxy
            .get('/products', {
              params: {
                page: currentPage,
                limit,
                featured: 0,
                locale,
                currency,
              },
            })
            .catch(() => {
              setLoading(false);
            });

        if (productResponse && productResponse.status === 200) {
          setProducts(productResponse.data.data);
          setPagination(productResponse.data.meta);
        }

        setLoading(false);
      })();
    }

    if (type === 'new') {
      (async () => {
        setLoading(true);
        const productResponse: void | AxiosResponse<PaginateResponseT<Array<ProductT>>> =
          await axiosInstanceWithoutProxy
            .get('/products', {
              params: {
                page: currentPage,
                new: 1,
                limit,
                locale,
                currency,
              },
            })
            .catch(() => {
              setLoading(false);
            });

        if (productResponse && productResponse.status === 200) {
          setProducts(productResponse.data.data);
          setPagination(productResponse.data.meta);
        }

        setLoading(false);
      })();
    }

    if (type === 'featured') {
      (async () => {
        setLoading(true);
        const productResponse: void | AxiosResponse<PaginateResponseT<Array<ProductT>>> =
          await axiosInstanceWithoutProxy
            .get('/products', {
              params: {
                page: currentPage,
                featured: 1,
                limit,
                locale,
                currency,
              },
            })
            .catch(() => {
              setLoading(false);
            });

        if (productResponse && productResponse.status === 200) {
          setProducts(productResponse.data.data);
          setPagination(productResponse.data.meta);
        }

        setLoading(false);
      })();
    }
  }, [currentPage, limit, type, locale, currency]);

  return { products, loading, pagination };
}
